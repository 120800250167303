.e82b10fd {
}

.c06f4cad {
  top: 100%;
  width: 99%;
  left: 0.5%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

._8a2f7809 {
  border-bottom: 1px solid #eee;
}

._8a2f7809:hover,
._7855648d {
  background-color: rgba(0, 0, 0, 0.05);
}

._8a2f7809:last-child {
  border-bottom: none;
}

._8a2f7809 span {
  display: inline-block;
  vertical-align: top;
  line-height: 2em;
}

._8a2f7809 a {
  vertical-align: top;
  line-height: 0;
}

._7c8e64da {
}

.e6f92c42 {
  background-color: rgba(1, 1, 1, 0);
}
