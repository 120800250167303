

:root {
  /*
    Semantically-named custom properties matching npm Figma Library
    and Primer naming conventions.

    These should be preferred over the below variables.

    Note that each variable has a corresponding class;
    `--color-fg-default` can be applied with `.color-fg-default`, etc.

    https://www.figma.com/file/qewGdfS6NAH5yOR3gKtWK9/?node-id=3%3A41
  */
  --color-fg-default: #262626;
  --color-fg-muted: #4d4d4d;
  --color-fg-subtle: #666666;
  --color-fg-on-emphasis: #ffffff;
  --color-fg-brand: #cb3837;
  --color-fg-accent: #196cb2;
  --color-fg-success: #107010;
  --color-fg-attention: #886701;
  --color-fg-danger: #bb2e3e;

  --color-bg-default: #ffffff;
  --color-bg-subtle: #f7f7f7;
  --color-bg-inset: #f2f2f2;
  --color-bg-emphasis: #262626;
  --color-bg-accent: #f2f9ff;
  --color-bg-success: #dcfdd9;
  --color-bg-attention: #fff5d8;
  --color-bg-danger: #ffdbdb;

  --color-border-default: #e6e6e6;
  --color-border-muted: #f2f2f2;
  --color-border-strong: #8f8f8f;
  --color-border-accent: #afcee9;
  --color-border-accent-emphasis: #0969da;
  --color-border-success: #93d58a;
  --color-border-success-emphasis: #107010;
  --color-border-attention: #efd88f;
  --color-border-danger: #f1b8bc;

  --color-shadow-large: 0 8px 24px rgba(140, 149, 159, 0.2);

  /*
    Legacy color variables — please avoid!
  */
  --wombat-red-hover: #c40b0a;
  --button-green: #00c642;
  --wombat-yellow: #ffcd3a;
  --wombat-violet: #8956ff;
  --wombat-purple: #c836c3;
  --wombat-green: #00c642;
  --wombat-teal: #29abe2;
  --light-blue: #70b8ff;
  --wombat-red: #cb3837;
  --header-color: #000;
  --bg-dark: #cb3837;
  --npmRed: #cb3837;
  --npmLightRed: #fb3e44;
  --blue: #357edd;
  --bg: #fff;
  --background-color: #fafafa;
  --wombat-bg-red: rgba(203, 55, 56, 0.1);

  --code: 'Fira Mono', 'Andale Mono', 'Consolas', monospace;
  --code-ls: 0px;
  --code-lh: 24px;

  --standardCardTransition: all 0.2s ease;

  --header-letter-spacing: 0.015625em;

  --readme-font-size: 18px;
  --readme-line-height: 1.6;
  --copy-color: rgba(0, 0, 0, 0.85);
  --code-bg: #f7f7f7;
  --code-box-radius: 2px;
}
:root {
  background-color: #fff;
  background-color: var(--bg);
}
._452c3ec4,
button,
input,
optgroup,
select,
textarea {
  font-family: 'Source Sans Pro', 'Lucida Grande', sans-serif;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
.d68c66a8 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.d68c66a8 main {
  position: relative;
  flex: 0 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
._12c81534 {
  background-color: #ffd9db;
  border-color: #ffd9db;
  color: #99171d;
}
._8a62ca63 {
  width: 100%;
  height: 2px;
  transition:
    opacity 500ms linear, transform 6s ease-out;
  transform-origin: 0 0;
  transition-timing-function: cubic-bezier(0.1, 0.1, 0, 1);
  background: #cb3837;
}
._657f443d {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}
.a3d7b97f {
  color: #cb3837;
  color: var(--wombat-red);
}
.ccdf7edd {
  color: inherit;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
._79c95847 {
  background: transparent;
  outline: none !important;
  border: none;
}
a {
  color: black;
}
/*
  Classes correspond to custom properties defined in vars.css
  and npm Figma library.
 */
._16dbbe04 {
  color: #262626 !important;
  color: var(--color-fg-default) !important;
}
._05e6e270 {
  color: #4d4d4d !important;
  color: var(--color-fg-muted) !important;
}
._2abd2378 {
  color: #666666 !important;
  color: var(--color-fg-subtle) !important;
}
._65c0b562 {
  color: #ffffff !important;
  color: var(--color-fg-on-emphasis) !important;
}
.e9458634 {
  color: #cb3837 !important;
  color: var(--color-fg-brand) !important;
}
._5e2f6685 {
  color: #196cb2 !important;
  color: var(--color-fg-accent) !important;
}
._846556b3 {
  color: #107010 !important;
  color: var(--color-fg-success) !important;
}
._8038b916 {
  color: #886701 !important;
  color: var(--color-fg-attention) !important;
}
.f0701c67 {
  color: #bb2e3e !important;
  color: var(--color-fg-danger) !important;
}
._6c06e3b3 {
  color: #ffffff !important;
  color: var(--color-bg-default) !important;
}
._0959e3a5 {
  color: #f7f7f7 !important;
  color: var(--color-bg-subtle) !important;
}
._14cd0905 {
  color: #f2f2f2 !important;
  color: var(--color-bg-inset) !important;
}
._0b0a3cb3 {
  color: #262626 !important;
  color: var(--color-bg-emphasis) !important;
}
.c1b4c25f {
  color: #f2f9ff !important;
  color: var(--color-bg-accent) !important;
}
.d494f2a8 {
  color: #dcfdd9 !important;
  color: var(--color-bg-success) !important;
}
._722ac90c {
  color: #fff5d8 !important;
  color: var(--color-bg-attention) !important;
}
.e1e5c7a4 {
  color: #ffdbdb !important;
  color: var(--color-bg-danger) !important;
}
._801d029e {
  color: #e6e6e6 !important;
  color: var(--color-border-default) !important;
}
._325ba73d {
  color: #f2f2f2 !important;
  color: var(--color-border-muted) !important;
}
.fc2a332a {
  color: #8f8f8f !important;
  color: var(--color-border-strong) !important;
}
.f70d78d8 {
  color: #afcee9 !important;
  color: var(--color-border-accent) !important;
}
._4a567d41 {
  color: #93d58a !important;
  color: var(--color-border-success) !important;
}
.a1208314 {
  color: #efd88f !important;
  color: var(--color-border-attention) !important;
}
._5c50006e {
  color: #f1b8bc !important;
  color: var(--color-border-danger) !important;
}
