._68da4dc6 {
  width: 480px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  margin: 25px auto;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

._23fda0fc {
  text-align: center;
}

.fc39eafa {
  margin: 16px 0 16px 0;
}

.ac956183 {
  margin-top: 16px;
}

._4b5568ee {
  padding: 16px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 8px;
  text-align: left;
}

.b5fec023 {
  margin: 16px 0 16px 0;
}

._712a8f69 {
}

._28833cd6 {
}

._45b7b7f7 {
  display: flex;
  justify-content: center;
  align-items: center;
}
